import React from "react";

import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ParallaxImage from "../components/parallaxImage";
import SafeZone from "../components/safeZone";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <ParallaxImage
      height="fullScreen"
      url="https://images.unsplash.com/uploads/14128324071271c853818/3765c625?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1651&q=80"
    >
      <h1>Page not found</h1>
    </ParallaxImage>
    <SafeZone>
      <p>
        It looks like you have found a route that doesn&apos;t go anywhere, much
        like work produced by outsourcing houses.
      </p>
      <p>
        For a truly scalable solution, get your skills boosted by our amazing
        principle engineers
        {" "}
        <Link to="/contact">Contact us</Link>
        {" "}
        to catch a ride.
      </p>
    </SafeZone>
  </Layout>
);

export default NotFoundPage;
